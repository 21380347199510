<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button type="primary" class="filter-item" size="small" @click="handleCreate">新增</el-button>

      <el-input v-model="listQuery.name" placeholder="名称" style="width: 200px;" class="filter-item" size="small" clearable/>

      <el-select v-model="listQuery.is_on" placeholder="状态" style="width: 120px" class="filter-item" size="small" clearable>
        <el-option label="下架" :value="0"/>
        <el-option label="正常" :value="1"/>
      </el-select>

      <el-button type="primary" class="filter-item" size="small" @click="handleFilter">搜索</el-button>

      <el-button type="success" class="filter-item" size="small" @click="downloadImportRecharge">充值信息模板</el-button>
    </div>

    <el-table
            v-loading="listLoading"
            :data="list"
            size="small"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row>
      <el-table-column label="ID" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>

      <el-table-column label="定时日期" min-width="180" align="center">
        <template slot-scope="scope">
          <el-tag v-for="(item,index) in scope.row.timing_dates" :key="index" style="margin: 3px">{{item}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="定时时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.timing_at }}
        </template>
      </el-table-column>

      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on === 1" type="success">正常</el-tag>
          <el-tag v-else-if="scope.row.is_on === 0" type="danger">下架</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="250" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="info" @click="handleInfo(scope.row)">详情</el-button>

          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>

          <el-upload accept=".xlsx,.xls" action="" :show-file-list="false" :auto-upload="false" :on-change="handleImportRechargeInfo">
            <el-button size="mini" type="warning" @click="handleBatchRecharge(scope.row)">导入充值信息</el-button>
          </el-upload>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="180px">
        <el-form-item label="名称" prop="name">
          <el-input type="text" v-model="form.name" clearable/>
        </el-form-item>

        <el-form-item label="定时日期" prop="timing_dates">
          <el-date-picker type="dates" v-model="form.timing_dates" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable></el-date-picker>
        </el-form-item>

        <el-form-item label="定时时间" prop="timing_at">
          <el-time-picker type="date" v-model="form.timing_at" format="HH:mm" value-format="HH:mm" clearable></el-time-picker>
        </el-form-item>

        <el-form-item label="状态" prop="is_on">
          <el-switch v-model="form.is_on" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->

    <!-- 详情 -->
    <el-dialog title="详情" :visible.sync="showDataInfo" width="60%">
      <el-form :model="dataInfo" label-width="180px" v-if="dataInfo">
        <el-form-item label="名称">
          {{ dataInfo.name }}
        </el-form-item>

        <el-form-item label="定时日期">
          <el-tag v-for="(item,index) in dataInfo.timing_dates" :key="index" style="margin: 3px">{{item}}</el-tag>
        </el-form-item>

        <el-form-item label="定时时间">
          {{ dataInfo.timing_at }}
        </el-form-item>

        <el-form-item label="充值信息">
          <div style="height: 500px" class="scrollbar" v-if="dataInfo.recharge_info">
            <el-scrollbar style="height: 500px">
              <el-table :data="dataInfo.recharge_info" style="width: 1100px">
                <el-table-column label="序号" min-width="60" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.index || "" }}
                  </template>
                </el-table-column>

                <el-table-column label="电话" min-width="60" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.phone || "" }}
                  </template>
                </el-table-column>

                <el-table-column label="金额" min-width="60" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.amount || "" }}
                  </template>
                </el-table-column>

                <el-table-column label="是否清空当前余额" min-width="60" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.is_clear || "" }}
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </div>
        </el-form-item>

        <el-form-item label="状态">
          <el-tag v-if="dataInfo.is_on === 1" type="success">正常</el-tag>
          <el-tag v-else-if="dataInfo.is_on === 0" type="danger">下架</el-tag>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 详情 -->

    <!-- 导入充值信息 -->
    <el-dialog title="导入数据预览" :visible.sync="importRechargeVisible" width="60%" :close-on-click-modal="false">
      <div style="height: 500px" class="scrollbar">
        <el-scrollbar style="height: 500px">
          <el-table :data="importRechargeData" style="width: 1100px">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.index || "" }}
              </template>
            </el-table-column>

            <el-table-column label="电话" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.phone || "" }}
              </template>
            </el-table-column>

            <el-table-column label="金额" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.amount || "" }}
              </template>
            </el-table-column>

            <el-table-column label="是否清空当前余额" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.is_clear || "" }}
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importRechargeVisible = false">取消</el-button>
        <el-button type="primary" @click="importRechargeSaveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 导入充值信息 -->
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";
  import downloadExcel from "vue-json-excel";
  import {excel2Json} from "@/utils/excel";

  export default {
    components: { downloadExcel },
    data() {
      return {
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: "",
          name: "",
          is_on: "",
        },
        dialogFormVisible: false,
        dialogStatus: "",
        textMap: {
          create: "新增",
          update: "修改",
        },
        form: {
          id: "",
          school_id: "",
          name: "",
          timing_dates: "",
          timing_at: "",
          is_on: 1,
        },
        rules: {
          name: [{ required: true, message: "名称不能为空", trigger: "change" }],
          timing_dates: [{ required: true, message: "定时日期不能为空", trigger: "change" }],
          timing_at: [{ required: true, message: "定时时间不能为空", trigger: "change" }],
          is_on: [{ required: true, message: "状态不能为空", trigger: "change" }],
        },
        btnLoading: false,
        // 详情
        showDataInfo: false,
        dataInfo: null,
        // 导入充值信息
        batchId: "",
        importRechargeData: [],
        importRechargeVisible: false,
      }
    },
    created() {
      this.getList()
    },
    computed: {
      ...mapGetters(["schools", "school_id","user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
      },
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true
        request({
          url: "/api/backend/wallet/timingRechargeList",
          method: "get",
          params: this.listQuery,
        }).then(response => {
          this.list = response.data.data
          this.total = response.data.total
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val
        this.getList()
      },
      resetForm() {
        this.form = {
          id: "",
          school_id: "",
          name: "",
          timing_dates: "",
          timing_at: "",
          is_on: 1,
        }
        this.btnLoading = false
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = "create"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = "update"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      saveData() {
        if (!this.school_id) {
          this.$message({
            type: "warning",
            message: "请选择学校"
          });
          return;
        } else {
          this.form.school_id = this.school_id;
        }

        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: "/api/backend/wallet/timingRechargeSave",
              method: "post",
              data: this.form
            }).then(() => {
              this.dialogFormVisible = false
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).catch((err) => {
              console.log(err)
            }).finally(() => {
              this.btnLoading = false
            })
          }
        })
      },
      handleDelete(row) {
        if (!this.school_id) {
          this.$message({
            type: "warning",
            message: "请选择学校"
          });
          return;
        }

        this.$confirm("确定继续该操作", "提示", {
          type: "warning"
        }).then(() => {
          request({
            url: "/api/backend/wallet/timingRechargeDelete",
            method: "post",
            data: {id: row.id, school_id: this.school_id},
          }).then(() => {
            this.$message({
              type: "success",
              message: "操作成功"
            });
            this.getList()
          })
        }).catch((err) => {
          console.log(err)
        });
      },
      // 详情
      handleInfo(row) {
        this.dataInfo = row
        this.showDataInfo = true
      },
      // 充值模板
      downloadImportRecharge() {
        let a = document.createElement("a");
        a.href = "/现场钱包充值模板.xlsx";
        a.download = "现场钱包充值模板.xlsx";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      // 导入充值信息
      handleBatchRecharge(row) {
        this.batchId = row.id;
      },
      handleImportRechargeInfo(file) {
        this.importRechargeData = [];
        file = file.raw;
        if (!(file.name.split(".")[1] === "xlsx" || file.name.split(".")[1] === "xls")) {
          this.$message.error("上传文件格式不正确，请检查选择的文件");
          return;
        }
        excel2Json(file, 1).then((excelData) => {
          // 处理业务
          if (excelData.length <= 0) {
            this.$message.error("数据不能为空");
            return;
          }
          if (excelData.length > 1500) {
            this.$message.error(`数据批量处理限制1500以内,当前数量${excelData.length}`);
            return;
          }
          excelData.forEach((element, index) => {
            this.importRechargeData.push({
              index: element["序号"]||"",
              phone: element["电话"].toString()||"",
              amount: element["金额"]||"",
              is_clear: element["是否清空当前余额"]||"",
            })
          });
          this.importRechargeVisible = true;
        });
      },
      importRechargeSaveData() {
        this.btnLoading = true;
        for (let index = 0; index < this.importRechargeData.length; index++) {
          const item = this.importRechargeData[index];
          for (var key in item) {
            if (!item[key]) {
              this.$message({
                type: "error",
                message: "请先完善数据",
              });
              this.btnLoading = false;
              return;
            }
          }
        }
        if (!this.school_id) {
          this.$message({
            type: "warning",
            message: "请选择学校"
          });
          this.btnLoading = false;
          return;
        }

        request({
          url: "/api/backend/wallet/importRechargeInfo",
          method: "post",
          data: {
            id: this.batchId,
            school_id: this.school_id,
            recharge_info: this.importRechargeData,
          },
          timeout: 10 * 60 * 1000,
        }).then((response) => {
          this.btnLoading = false;
          this.importRechargeVisible = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getList();
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          this.btnLoading = false;
        });
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
